<template>
    <form @submit.prevent="updateUser" class="invite-form">
        <h3>Edit user</h3>

        <div class="card">
            <div class="card-body">
                <div class="form-group">
                    <label for="first_name">{{ $t('First name') }}</label>
                    <input v-model="user.profile.first_name" type="text" id="first_name" required>
                    <div v-if="errors['profile.first_name']" v-for="error in errors['profile.first_name']" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <label for="last_name">{{ $t('Last name') }}</label>
                    <input v-model="user.profile.last_name" type="text" id="last_name" required>
                    <div v-if="errors['profile.last_name']" v-for="error in errors['profile.last_name']" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <label for="email">{{ $t('Email') }}</label>
                    <input v-model="user.email" type="email" id="email" required>
                    <div v-if="errors[`email`]" v-for="error in errors[`email`]" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <label for="phone_number">{{ $t('Phone') }}</label>
                    <input v-model="user.profile.phone_number" type="text" id="phone_number">
                    <div v-if="errors['profile.phone_number']" v-for="error in errors['profile.phone_number']" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div v-if="changePassword" class="form-group">
                    <label for="password">{{ $t('Password') }}</label>
                    <input v-model="user.password" type="password" id="password" required>
                    <div v-if="errors[`password`]" v-for="error in errors[`password`]" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div v-else class="form-group">
                    <a @click.prevent="changePassword=true" href=""><h2 class="text-success">{{ $t('Change Password') }}</h2></a>
                </div>

                <div class="form-group">
                    <label for="language">{{ $t('Language') }}</label>
                    <select class="form-control" v-model="user.profile.language_id" id="language">
                        <option value="undefined">Select language</option>
                        <option v-for="lang in languages" :value="lang.id">{{ lang.name }}</option>
                    </select>
                    <div v-if="errors['profile.language']" v-for="error in errors['profile.language']" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <label for="country">{{ $t('Country') }}</label>
                    <select class="form-control" v-model="user.profile.country_id" id="country">
                        <option value="undefined">Select country</option>
                        <option v-for="country in countries" :value="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                    <div v-if="errors['profile.country']" v-for="error in errors['profile.country']" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <div class="form-group">
                    <label for="group">{{ $t('User Group') }}</label>
                    <select class="form-control" v-model="user.user_group_id" id="group">
                        <option v-for="userGroup in userGroups" :value="userGroup.id">
                            {{ userGroup.name }}
                        </option>
                    </select>
                    <div v-if="errors[`user_group_id`]" v-for="error in errors[`user_group_id`]" :key="error" class="text-danger">
                        {{ error }}
                    </div>
                </div>

                <router-link :to="'/settings/users?id=' + user.id" class="card-link">Cancel link</router-link>
                <a @click.prevent="updateUser" href="#" class="card-link">Update link</a>
            </div>

        </div>
    </form>
</template>

<script>
import {userService} from '@/services/settings/userService'

export default {
    name: "EditUser",
    data() {
        return {
            user: {
                profile: {},
            },


            changePassword: false,
        }
    },
    computed: {
        userId() {
            return this.$route.params.id
        },
        userGroups() {
            return this.$store.getters.userGroups
        },
        countries() {
            return this.$store.getters.countries
        },
        languages() {
            return this.$store.getters.languages
        }
    },
    created() {
        this.getUser()
    },
    methods: {
        getUser() {
            userService.showUser(this.userId).then(response => {
                this.user = response.data.data
            })
        },
        updateUser() {
            this.errors = []
            userService.update(this.userId, this.user).catch(error => {
                this.errors = error.response.data.errors || []
            })
        }
    }
}
</script>